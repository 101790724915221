























































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { Invitation } from "@/core/models";

@Component
export default class Home extends Vue {
  @Getter("invitations/invitations") invitations!: Invitation[];
  @Getter("invitations/retrieved") retrieved!: boolean;
  @Getter("invitations/loading") loading!: boolean;
  @Action("invitations/get") getInvitations!: () => Promise<void>;
  @Action("invitations/accept") accept!: (i: Invitation) => Promise<void>;
  @Action("invitations/decline") decline!: (i: Invitation) => Promise<void>;

  getLoading(inv: Invitation) {
    return this.processing && this.affectedInv?.id === inv.id;
  }
  getTitle(inv: Invitation) {
    return `You've been invited to the seminar "${inv.seminarName}"`;
  }

  processing = false;
  affectedInv: Invitation | null = null;
  async handleAction(inv: Invitation, accept = false) {
    this.processing = true;
    this.affectedInv = inv;
    accept ? await this.accept(inv) : await this.decline(inv);
    this.processing = false;
    this.affectedInv = null;
  }

  created() {
    if (!this.retrieved) this.getInvitations();
  }
}
