




















import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import Invitations from "@/components/common/Invitations.vue";
import SeminarList from "./components/SeminarList.vue";
import { Seminar } from "@/core/models";

@Component({ components: { Invitations, SeminarList } })
export default class Home extends Vue {
  @Getter("profile/loading") loading!: boolean;
  @Getter("profile/permissions") permissions?: string[];
  @Getter("seminars/seminars") seminars!: Seminar[];

  get hasNoPermissions() {
    return !this.permissions?.length;
  }
  @Watch("loading")
  userLoaded() {
    if (this.permissions?.includes("session_training"))
      this.$router.replace("/dashboard");
  }
}
